<template>
  <div class="col-12 xl:col-6">
    <div class="card">
      <h5 class="text-center">Company Profile</h5>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <!-- Language -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="language" v-model="v$.form.language.$model" :class="{'p-invalid':v$.form.language.$invalid && submitted}"/>
            <label for="language" :class="{'p-error':v$.form.language.$invalid && submitted}">Language*</label>
          </div>

          <small
            v-if="(v$.form.language.$invalid && submitted) || v$.form.language.$pending.$response"
            class="p-error">{{ v$.form.language.required.$message.replace('Value', 'Language') }}
          </small>
        </div>

        <!-- Business address -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="business_address" class="disabled" v-model="business_address" disabled/>
            <label for="business_address">Business address</label>
          </div>
        </div>

        <!-- Business name -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="business_name" class="disabled" v-model="business_name" disabled/>
            <label for="business_name">Business name</label>
          </div>
        </div>

        <!-- Business type -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="business_type" class="disabled" v-model="business_type" disabled/>
            <label for="business_type">Business type</label>
          </div>
        </div>

        <!-- Facebook url -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="facebook_url" v-model="v$.form.facebook_url.$model" :class="{'p-invalid':v$.form.facebook_url.$invalid && submitted}"/>
            <label for="facebook_url" :class="{'p-error':v$.form.facebook_url.$invalid && submitted}">Facebook url</label>
          </div>

          <small
            v-if="(v$.form.facebook_url.$invalid && submitted) || v$.form.facebook_url.$pending.$response"
            class="p-error">{{ v$.form.facebook_url.url.$message.replace('Value', 'Facebook url') }}
          </small>
        </div>

        <!-- Instagram url -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="instagram_url" v-model="v$.form.instagram_url.$model" :class="{'p-invalid':v$.form.instagram_url.$invalid && submitted}"/>
            <label for="instagram_url" :class="{'p-error':v$.form.instagram_url.$invalid && submitted}">Instagram url</label>
          </div>

          <small
            v-if="(v$.form.instagram_url.$invalid && submitted) || v$.form.instagram_url.$pending.$response"
            class="p-error">{{ v$.form.instagram_url.url.$message.replace('Value', 'Instagram url') }}
          </small>
        </div>

        <!-- Linkedin url -->
        <div class="field lmb-2">
          <div class="p-float-label">
            <InputText id="linkedin_url" v-model="v$.form.linkedin_url.$model" :class="{'p-invalid':v$.form.linkedin_url.$invalid && submitted}"/>
            <label for="linkedin_url" :class="{'p-error':v$.form.linkedin_url.$invalid && submitted}">Linkedin url</label>
          </div>

          <small
            v-if="(v$.form.linkedin_url.$invalid && submitted) || v$.form.linkedin_url.$pending.$response"
            class="p-error">{{ v$.form.linkedin_url.url.$message.replace('Value', 'Linkedin url') }}
          </small>
        </div>

        <Button type="submit" label="Submit" class="mt-2"/>
      </form>
    </div>
  </div>
</template>

<script>
import { url, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import profileResource from "@http/ProfileResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import company from "@/models/Company";

export default {
  setup: () => ({v$: useVuelidate()}),

  data() {
    return {
      form: {
        language: '',
        facebook_url: '',
        instagram_url: '',
        linkedin_url: '',
      },
      business_address: '',
      business_name: '',
      business_type: '',
      submitted: false,
    }
  },

  mounted() {
    this.getCompanyData();
  },

  methods: {
    getCompanyData() {
      profileResource.getCompanyData()
        .then(({data}) => {
          this.form.language = data.language;
          this.form.facebook_url = data.facebook_url;
          this.form.instagram_url = data.instagram_url;
          this.form.linkedin_url = data.linkedin_url;
          this.business_address = data.business_address;
          this.business_name = data.business_name;
          this.business_type = company.typesOfBusiness.filter(el => el.id === data.business_type)[0]['name'];
        });
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      profileResource.updateCompanyData(this.form)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },
  },

  validations() {
    return {
      form: {
        language: { required },
        facebook_url: { url },
        instagram_url: { url },
        linkedin_url: { url },
      },
    };
  },
}
</script>

<style scoped>
  .lmb-2 {
    margin-bottom: 2rem;
  }

  .disabled {
    cursor: not-allowed;
  }
</style>
