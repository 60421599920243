import axios from "@/libs/axios";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default {
  getPersonalData() {
    return axios.get(`/${endpointPrefix}/profile/personal`);
  },

  updatePersonalData(payload) {
    return axios.post(`/${endpointPrefix}/profile/personal`, payload);
  },

  getCompanyData() {
    return axios.get(`/${endpointPrefix}/profile/company`);
  },

  updateCompanyData(payload) {
    return axios.post(`/${endpointPrefix}/profile/company`, payload);
  },
}
