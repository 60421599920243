<template>
  <div class="profile">
    <personal-profile />
    <company-profile
      v-if="showCompanyProfile"
    />
  </div>
</template>

<script>
import PersonalProfile from '@/pages/app/Profile/PersonalProfile';
import CompanyProfile from "@/pages/app/Profile/CompanyProfile";
import user from '@/models/User';
import store from "@/store";

export default {
  components: {
    CompanyProfile,
    PersonalProfile,
  },

  computed: {
    showCompanyProfile() {
      return [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN].includes(store.getters['auth/getRole']);
    }
  }
}
</script>

<style>
  .profile {
    display: flex;
    flex-wrap: wrap;
  }

  .profile > .profile-item {
    flex: 50%;
    padding: 0 10px;
  }
</style>
